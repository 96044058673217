import React, { lazy } from 'react'

import Account from './Account'
import Admin from './Admin'
import Legal from './Legal'
import Study from './Study'
import User from './User'
import Translations from './Translations'

const AdminLayout = lazy(() => import('layouts/admin'))

export default () => ({
	path: 'admin',
	element: <AdminLayout />,
	children: [Account(), Admin(), Legal(), Study(), User(), Translations()],
})
