import { COLORS } from 'constants/colors'

/**
 * @param {{fill: string | undefined | null, color: string | undefined | null}} statement
 *
 * @returns {boolean}
 */
const getFill = statement => statement.fill || statement.color

/**
 * @param {Object.<string, unknown>} statement
 * @param {Object.<string, unknown>} totalSegmentStatement
 * @param {Object.<string, unknown>[]} statementThemes
 * @param {Object.<string, unknown>[]} ideas
 * @param {boolean | null} useThemes
 * @param {boolean} isThemeViewActive
 *
 * @returns {{color: string, name: string | null, originalLanguageThemeName: string | null}}
 */
export const getThemeParams = (
	statement,
	totalSegmentStatement,
	statementThemes,
	ideas,
	useThemes,
	isThemeViewActive,
) => {
	if (isThemeViewActive === true) {
		if (totalSegmentStatement.isStatementTheme === true) {
			return {
				color: getFill(totalSegmentStatement),
				name: null,
				originalLanguageThemeName: null,
				idStatementTheme: null,
				hasCustomThemeColor: false,
			}
		}

		return {
			color: COLORS.REPORT_BUILDER_NO_THEME,
			name: null,
			originalLanguageThemeName: null,
			idStatementTheme: null,
			hasCustomThemeColor: false,
		}
	}

	const idsIdeas = ideas.map(statement => statement.idStatement)

	const hasUsedThemes =
		statementThemes.length > 0 &&
		statementThemes.some(theme => idsIdeas.some(id => theme.statements.includes(id) === true))

	if (useThemes === false || hasUsedThemes === false) {
		return {
			color: getFill(totalSegmentStatement),
			name: null,
			originalLanguageThemeName: null,
			idStatementTheme: null,
			hasCustomThemeColor: false,
		}
	}

	const statementTheme = statementThemes.find(theme =>
		theme.statements.includes(statement.idStatement),
	)

	if (statementTheme === undefined) {
		return {
			color: COLORS.REPORT_BUILDER_NO_THEME,
			name: null,
			originalLanguageThemeName: null,
			idStatementTheme: null,
			hasCustomThemeColor: false,
		}
	}

	return {
		color: statementTheme.color,
		name: statementTheme.name,
		originalLanguageThemeName: statementTheme.originalLanguageThemeName,
		idStatementTheme: statementTheme.idStatementTheme,
		hasCustomThemeColor: statementTheme.hasCustomColor,
	}
}
