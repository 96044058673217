import _ from 'lodash'

import { CHOICE_OPTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/CHOICE_OPTION'
import { getDefaultOptionLabel } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getDefaultOptionLabel'
import { getOptionLabelIdTranslation } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getOptionLabelIdTranslation'

const getNewOptionCode = options => Math.max(...options.map(option => option.code)) + 1

const getNewOptionLabel = (options, moduleType, isNoneOfThese, languages, activeLanguage) => {
	const idTranslation = getOptionLabelIdTranslation(moduleType, isNoneOfThese)

	if (isNoneOfThese === true) {
		return getDefaultOptionLabel(idTranslation, languages, false)
	}

	const labels = options.map(option => option.label[activeLanguage])

	const labelNumbers = labels
		.map(label => _.last(label.split(' ')))
		.filter(number => isNaN(number) === false && _.isNil(number) === false)

	const index =
		labelNumbers.length > 0
			? Math.max(...labelNumbers)
			: options.filter(o => o.isNoneOfThese === false).length

	return getDefaultOptionLabel(idTranslation, languages, true, index)
}

export const createNewOption = (
	subtype,
	isNoneOfThese,
	options,
	showLabel,
	moduleType,
	languages,
	activeLanguage,
) => {
	const code = getNewOptionCode(options)
	const label = getNewOptionLabel(options, moduleType, isNoneOfThese, languages, activeLanguage)

	return CHOICE_OPTION(subtype, isNoneOfThese, code, label, showLabel, languages, null, null)
}

export const createOptionsOnPaste = (
	labels,
	index,
	allOptions,
	subtype,
	showLabel,
	languages,
	activeLanguage,
) => {
	const newOptions = _.cloneDeep(allOptions)

	const mutableOptions = newOptions.filter(option => option.isNoneOfThese === false)
	const noneOfTheseOption = newOptions.find(option => option.isNoneOfThese === true)

	const labelsToCreate = labels.filter(label => label.length > 0)

	labelsToCreate.forEach((label, labelIndex) => {
		const optionIndex = index + labelIndex

		const optionLabel = getDefaultOptionLabel(label, languages, false)

		// replace option if it exists
		// create new option otherwise
		if (mutableOptions[optionIndex] !== undefined) {
			mutableOptions[optionIndex].label[activeLanguage] = optionLabel[activeLanguage]
		} else {
			mutableOptions[optionIndex] = CHOICE_OPTION(
				subtype,
				false,
				optionIndex + 1,
				optionLabel,
				showLabel,
				languages,
				null,
				null,
			)
		}
	})

	const optionsToReturn =
		noneOfTheseOption === undefined ? mutableOptions : [...mutableOptions, noneOfTheseOption]

	return optionsToReturn.map((option, optionIndex) => ({
		...option,
		code: optionIndex + 1,
	}))
}
