import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Field, FieldArray } from 'formik'
import { useIntl } from 'react-intl'

import {
	ALLOCATION_SUBTYPE,
	CHOICE_OPTION_ORDER_MODE,
	CHOICE_SUBTYPE,
	LIST_INPUT_TYPE,
	RANKING_SUBTYPE,
	VISUAL_FLOW_MODULE_TYPES,
} from 'constants/studyDesign'
import { studyLanguagesShape } from 'constants/languages/studyLanguagesShape'
import { translations } from 'languages'
import { useActiveStudyQuery } from 'hooks/useActiveStudyQuery'

import isChoiceOptionNoneOfThese from 'helpers/visualFlowModules/isChoiceOptionNoneOfThese'
import { findSelectedList } from 'helpers/visualFlowModules/findSelectedList'
import { createChoiceOptionsFromList } from 'helpers/visualFlowModules/createChoiceOptionsFromList'
import { getDefaultDynamicOptionsSettings } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/CHOICE'
import { getAvailableInputListOptions } from 'helpers/visualFlowModules/getAvailableInputListOptions'
import { getIsStudyEditable } from 'helpers/studyList/getIsStudyEditable'

import {
	hasDuplicateId,
	hasDuplicateLabel,
	hasDuplicateSimpleName,
	hasInvalidId,
} from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/validateChoice.js'

import Checkbox from 'components/_formik/_base/Checkbox'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'
import FormikSelect from 'components/_formik/_base/Select'
import Tooltip from 'components/_scaffolding/Tooltip'

import Icon from 'components/_scaffolding/Icon'
import Select from 'components/_scaffolding/_input/Select'

import {
	createNewOption,
	createOptionsOnPaste,
} from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/createOptions'
import { getOptionLabelIdTranslation } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getOptionLabelIdTranslation'

import DynamicOptionsPreview from './_components/DynamicOptionsPreview'
import Option from './_components/Option'
import OptionsHeader from './_components/OptionsHeader'

import classes from './ChoiceOptions.module.scss'

const getNoneOfTheseOptionIndex = options => options.findIndex(isChoiceOptionNoneOfThese)

const getShowLabelValue = options => options.every(o => o.showLabel === true)

const addNewOption = (values, getChoiceOption, setFieldValue) => {
	const newOptions = [...values.options]
	// make sure that none of these is always the last element of options array
	if (values.options.some(isChoiceOptionNoneOfThese) === true) {
		newOptions.splice(-1, 0, getChoiceOption())
	} else {
		newOptions.push(getChoiceOption())
	}
	setFieldValue('options', newOptions)
}

const addNoneOfTheseToOptions = (options, subtype, languages, activeLanguage) => {
	if (options.some(isChoiceOptionNoneOfThese)) {
		return options.map(option => {
			if (isChoiceOptionNoneOfThese(option) === false) {
				return option
			}

			return {
				...option,
				isHidden: false,
			}
		})
	} else {
		const noneOfTheseOption = createNewOption(
			subtype,
			true,
			options,
			getShowLabelValue(options),
			null,
			languages,
			activeLanguage,
		)
		const modifiedOptions = options.slice()
		modifiedOptions.push(noneOfTheseOption)

		return modifiedOptions
	}
}

const removeNoneOfTheseFromOptions = (options, isStudyEditable) => {
	const noneOfTheseOption = options.find(isChoiceOptionNoneOfThese)

	if (isStudyEditable === true || noneOfTheseOption.isUnsaved === true) {
		return options.filter(option => isChoiceOptionNoneOfThese(option) === false)
	} else {
		return options.map(option => {
			if (isChoiceOptionNoneOfThese(option) === false) {
				return option
			}

			return {
				...option,
				isHidden: true,
			}
		})
	}
}

const ChoiceOptions = props => {
	const intl = useIntl()
	const { study } = useActiveStudyQuery()

	if (study === null) {
		return null
	}

	const studyState = study.state

	const { activeLanguage, disabled, setFieldValue, setValues, values } = props

	const noneOfTheseOptionIndex = getNoneOfTheseOptionIndex(values.options)

	const isMatrixChoice = props.isMatrixChoice ?? false
	const upperLists = props.upperLists ?? []

	const getChoiceOption = () =>
		createNewOption(
			values.subtype,
			false,
			values.options,
			getShowLabelValue(values.options),
			props.moduleType,
			props.languages,
			activeLanguage,
		)

	const handleMultiLinePaste = (labels, index) => {
		setFieldValue(
			'options',
			createOptionsOnPaste(
				labels,
				index,
				values.options,
				values.subtype,
				getShowLabelValue(values.options),
				props.languages,
				activeLanguage,
			),
		)
	}

	const handleDragEnd = result => {
		const { destination, source, reason } = result

		if (destination === null || reason === 'CANCEL') return

		if (destination.droppableId === source.droppableId && destination.index === source.index) return

		const newOptions = values.options.slice()
		const droppedOption = newOptions[source.index]
		newOptions.splice(source.index, 1)
		newOptions.splice(destination.index, 0, droppedOption)

		setFieldValue('options', newOptions)
	}

	const getDefaultLabel = (isNoneOfThese, index) => {
		const idTranslation = getOptionLabelIdTranslation(props.moduleType, isNoneOfThese)
		const translation = translations[activeLanguage][idTranslation]

		return `${translation} ${index + 1}`
	}

	const getOptionPlaceholder = index => {
		if (values.subtype === ALLOCATION_SUBTYPE.INPUT) {
			return intl.formatMessage({ id: 'allocation.option.default_label' }, { index: index + 1 })
		}

		if (
			values.subtype === RANKING_SUBTYPE.RANKING_DRAG_AND_DROP ||
			values.subtype === RANKING_SUBTYPE.RANKING_SELECT
		) {
			return intl.formatMessage({ id: 'ranking.option.default_label' }, { index: index + 1 })
		}

		if (props.moduleType === VISUAL_FLOW_MODULE_TYPES.MAXDIFF) {
			return intl.formatMessage({ id: 'maxDiff.option.default_label' }, { index: index + 1 })
		}

		return intl.formatMessage({ id: 'choice.option.default_label' }, { index: index + 1 })
	}

	const renderStaticOptions = () => (
		<Fragment>
			<OptionsHeader
				disabled={props.disabled}
				isMatrixChoice={isMatrixChoice}
				moduleType={props.moduleType}
				regenerateCodeValues={props.regenerateCodeValues}
				subtype={values.subtype}
			/>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable droppableId="droppable">
					{provided => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							<FieldArray name="options">
								{arrayHelpers => (
									<Fragment>
										{values.options.map((option, index) => (
											<Draggable
												draggableId={option.id}
												index={index}
												isDragDisabled={disabled}
												key={option.id}
											>
												{provided => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<Option
															activeLanguage={activeLanguage}
															arrayHelpers={arrayHelpers}
															choiceSubtype={values.subtype}
															defaultLabel={getDefaultLabel(false, index)}
															disabled={disabled}
															expandOption={props.expandOption}
															getChoiceOption={getChoiceOption}
															getNormalOptionsCount={props.getNormalOptionsCount}
															handleMultiLinePaste={handleMultiLinePaste}
															// TODO: complete language object or rework validation to work with language codes only
															hasDuplicateLabel={hasDuplicateLabel(option, values.options, [
																{ language: props.activeLanguage },
															])}
															hasDuplicateSimpleName={hasDuplicateSimpleName(
																option,
																values.options,
																[{ language: props.activeLanguage }],
															)}
															hasDuplicateId={hasDuplicateId(option, values.options)}
															hasInvalidId={hasInvalidId(option)}
															isExpanded={_.get(props.listState, option.id, false)}
															index={index}
															key={`option-${option.id}`}
															label={intl.formatMessage(
																{ id: 'choice.detail.option.label' },
																{ index: index + 1 },
															)}
															moduleType={props.moduleType}
															option={option}
															placeholder={getOptionPlaceholder(index)}
															setFieldValue={setFieldValue}
															setValues={setValues}
															showNoneOfThese={false}
															toggleOption={props.toggleOption}
															values={values}
															isInternalEmployee={props.isInternalEmployee}
															openMediaManager={props.openMediaManager}
															studyState={studyState}
														/>
													</div>
												)}
											</Draggable>
										))}
									</Fragment>
								)}
							</FieldArray>
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			{disabled === false && (
				<div className={classes.add__holder}>
					<div
						className={classes.add__button}
						onClick={() => {
							addNewOption(values, getChoiceOption, setFieldValue)
						}}
					>
						<Icon name={Icon.NAMES.ADD} size={24} />
						{intl.formatMessage({
							id: 'choice.detail.add_option',
						})}
					</div>
				</div>
			)}
		</Fragment>
	)

	const handleChoiceListChange = option => {
		const selectedList = upperLists.find(list => list.definition.id === option.value)

		const idInputList = option.value
		const mainList =
			selectedList.definition.inputType === LIST_INPUT_TYPE.FILE
				? selectedList
				: findSelectedList(selectedList.definition.idInputList, upperLists)

		const idMainList = mainList.definition.id

		if (idMainList === values.dynamicOptionsSettings.idMainList) {
			setFieldValue('dynamicOptionsSettings.idInputList', idInputList)

			return
		}

		const { optionLabelIdentifiers } = mainList.definition

		const existingNoneOfTheseOptions = values.options.filter(isChoiceOptionNoneOfThese)

		setValues({
			...values,
			options: [
				...createChoiceOptionsFromList(
					mainList,
					optionLabelIdentifiers,
					values.subtype,
					props.languages,
				),
				...existingNoneOfTheseOptions,
			],
			dynamicOptionsSettings: {
				...values.dynamicOptionsSettings,
				idMainList,
				idInputList,
				optionLabelColumn: optionLabelIdentifiers,
			},
		})
	}

	const optionsOrderSelectOptions = [
		{
			value: CHOICE_OPTION_ORDER_MODE.QUESTION_ORDER,
			label: intl.formatMessage({ id: 'randomize' }),
		},
		{
			value: CHOICE_OPTION_ORDER_MODE.INPUT_LIST_ORDER,
			label: intl.formatMessage({ id: 'choice.detail.list.options_order.list' }),
		},
	]

	const handleOptionLabelColumnChange = selectedOption => {
		setValues({
			...values,
			options: values.options.map((option, optionIndex) => {
				const selectedList = findSelectedList(values.dynamicOptionsSettings.idInputList, upperLists)

				const items = selectedList.definition.items

				return {
					...option,
					label: {
						...option.label,
						[activeLanguage]:
							option.isNoneOfThese === true
								? option.label[activeLanguage]
								: items[optionIndex][selectedOption.value],
					},
				}
			}),
			dynamicOptionsSettings: {
				...values.dynamicOptionsSettings,
				optionLabelColumn: {
					...values.dynamicOptionsSettings.optionLabelColumn,
					[activeLanguage]: selectedOption.value,
				},
			},
		})
	}

	const renderDynamicOptions = () => {
		const listOptions = getAvailableInputListOptions(
			getIsStudyEditable(studyState),
			props.isUnsaved,
			upperLists.find(list => list.definition.id === values.dynamicOptionsSettings.idMainList),
			upperLists,
		)

		const getSelectedListValue = () => {
			return (
				listOptions.find(
					listOption => listOption.value === values.dynamicOptionsSettings.idInputList,
				) ?? null
			)
		}

		const optionLabelColumnOptions =
			isMatrixChoice === true ||
			values.dynamicOptionsSettings?.isActive !== true ||
			values.dynamicOptionsSettings.idInputList === null
				? []
				: findSelectedList(values.dynamicOptionsSettings.idInputList, upperLists)
						?.definition.columns.filter(column => column.isUnique === true)
						.map(column => ({
							value: column.key,
							label: column.key,
						})) ?? []

		const optionLabelColumnValue =
			optionLabelColumnOptions.find(
				option => option.value === values.dynamicOptionsSettings.optionLabelColumn[activeLanguage],
			) ?? null

		const selectedInputList = upperLists.find(
			list => list.definition.id === values.dynamicOptionsSettings.idInputList,
		)

		return (
			<Fragment>
				<Select
					id={'choice-list-select'}
					isDisabled={props.disabled}
					isSearchable={false}
					onChange={handleChoiceListChange}
					options={listOptions}
					value={getSelectedListValue()}
					placeholder={intl.formatMessage({
						id: 'select_list_placeholder',
					})}
				/>
				{values.dynamicOptionsSettings.idInputList === null && (
					<div className="title-info">{intl.formatMessage({ id: 'select_list_info' })}</div>
				)}
				{values.dynamicOptionsSettings.idInputList !== null && selectedInputList === undefined && (
					<div className="title-error">{intl.formatMessage({ id: 'selected_list_removed' })}</div>
				)}
				<Label label={intl.formatMessage({ id: 'choice.detail.list.options_order' })} />
				<Field
					component={FormikSelect}
					disabled={props.disabled}
					name={'dynamicOptionsSettings.optionsOrderMode'}
					options={optionsOrderSelectOptions}
				/>
				{selectedInputList !== undefined && (
					<Fragment>
						<Label
							label={intl.formatMessage({
								id: 'choice.detail.list.label',
							})}
						/>
						<Select
							id={`dynamicOptionsSettings.optionLabelColumn.${activeLanguage}`}
							isDisabled={props.disabled}
							isSearchable={false}
							onChange={handleOptionLabelColumnChange}
							options={optionLabelColumnOptions}
							value={optionLabelColumnValue}
							placeholder={intl.formatMessage({
								id: 'choice.detail.list.label',
							})}
						/>
						{optionLabelColumnValue === null && (
							<div className="title-error">
								{intl.formatMessage({ id: 'choice.detail.list.invalid.column' })}
							</div>
						)}
						<DynamicOptionsPreview
							activeLanguage={activeLanguage}
							disabled={props.disabled}
							options={values.options}
							setFieldValue={setFieldValue}
						/>
					</Fragment>
				)}
			</Fragment>
		)
	}

	const isNoneOfTheseActive =
		noneOfTheseOptionIndex > -1 && values.options[noneOfTheseOptionIndex].isHidden !== true

	return (
		<Fragment>
			{[
				VISUAL_FLOW_MODULE_TYPES.ALLOCATION,
				VISUAL_FLOW_MODULE_TYPES.A_CHOICE,
				VISUAL_FLOW_MODULE_TYPES.RANKING,
			].includes(props.moduleType) &&
				props.isMatrixChoice !== true && (
					<div
						className={classes['dynamic-options-checkbox']}
						data-for={'dynamicOptionsSettings-checkbox'}
						data-tip={intl.formatMessage({
							id: 'choice.detail.list.image_choice',
						})}
						data-tip-disable={values.subtype !== CHOICE_SUBTYPE.IMAGE}
						data-place="bottom"
					>
						<Field
							component={Checkbox}
							componentProps={{
								label: intl.formatMessage({
									id: 'choice.detail.mode',
								}),
							}}
							disabled={
								props.disabled ||
								values.subtype === CHOICE_SUBTYPE.IMAGE ||
								(getIsStudyEditable(studyState) === false && props.isUnsaved !== true)
							}
							name={'dynamicOptionsSettings.isActive'}
							onChange={value => {
								setFieldValue('dynamicOptionsSettings', {
									...getDefaultDynamicOptionsSettings(props.languages),
									isActive: value,
								})
							}}
						/>
						<Tooltip id={'dynamicOptionsSettings-checkbox'} />
					</div>
				)}
			<div
				className={classnames(classes.wrapper, {
					[classes['wrapper--dynamic']]: values.dynamicOptionsSettings?.isActive === true,
				})}
			>
				{values.dynamicOptionsSettings?.isActive !== true
					? renderStaticOptions()
					: renderDynamicOptions()}
				{props.showNoneOfTheseOption === true && (
					<div
						className={classnames(classes['none-of-these-toggle'], {
							[classes['none-of-these-toggle--dynamic']]:
								values.dynamicOptionsSettings?.isActive === true,
						})}
					>
						<Checkbox
							isDarkTheme
							componentProps={{
								label: intl.formatMessage({ id: 'choice.detail.none_of_these.add' }),
							}}
							disabled={disabled}
							field={{
								onBlur: () => {},
								onChange: () => {},
								value: isNoneOfTheseActive,
								name: 'noneOfThese',
							}}
							form={{ setFieldValue }}
							onChange={value => {
								if (value === true) {
									setFieldValue(
										'options',
										addNoneOfTheseToOptions(
											values.options,
											values.subtype,
											props.languages,
											activeLanguage,
										),
									)
								} else {
									setFieldValue(
										'options',
										removeNoneOfTheseFromOptions(values.options, getIsStudyEditable(studyState)),
									)
								}
							}}
						/>
					</div>
				)}
				{props.showNoneOfTheseOption === true &&
					isNoneOfTheseActive === true &&
					values.dynamicOptionsSettings?.isActive !== true && (
						<Option
							activeLanguage={activeLanguage}
							arrayHelpers={{}}
							defaultLabel={getDefaultLabel(true)}
							choiceSubtype={values.subtype}
							disabled={disabled}
							expandOption={props.expandOption}
							getChoiceOption={getChoiceOption}
							getNormalOptionsCount={props.getNormalOptionsCount}
							handleMultiLinePaste={handleMultiLinePaste}
							hasDuplicateLabel={hasDuplicateLabel(
								values.options[noneOfTheseOptionIndex],
								values.options,
								[{ language: props.activeLanguage }],
							)}
							hasDuplicateSimpleName={hasDuplicateSimpleName(
								values.options[noneOfTheseOptionIndex],
								values.options,
								[{ language: props.activeLanguage }],
							)}
							hasDuplicateId={hasDuplicateId(
								values.options[noneOfTheseOptionIndex],
								values.options,
							)}
							hasInvalidId={hasInvalidId(values.options[noneOfTheseOptionIndex])}
							isExpanded={_.get(props.listState, values.options[noneOfTheseOptionIndex].id, false)}
							index={noneOfTheseOptionIndex}
							key="none-of-these"
							label={intl.formatMessage({ id: 'choice.detail.none_of_these.label' })}
							moduleType={props.moduleType}
							option={values.options[noneOfTheseOptionIndex]}
							setFieldValue={setFieldValue}
							setValues={setValues}
							showNoneOfThese
							toggleOption={props.toggleOption}
							values={values}
							isInternalEmployee={props.isInternalEmployee}
							openMediaManager={_.noop}
							placeholder={intl.formatMessage({ id: 'none_of_these' })}
							studyState={studyState}
						/>
					)}
				{props.showNoneOfTheseOption === true &&
					isNoneOfTheseActive === true &&
					values.dynamicOptionsSettings?.isActive === true && (
						<Field
							component={Input}
							name={`options[${noneOfTheseOptionIndex}].label.${activeLanguage}`}
							disabled={props.disabled}
							type="text"
							placeholder={intl.formatMessage({ id: 'none_of_these' })}
						/>
					)}
			</div>
		</Fragment>
	)
}

ChoiceOptions.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	expandOption: PropTypes.func.isRequired,
	getNormalOptionsCount: PropTypes.func.isRequired,
	isMatrixChoice: PropTypes.bool,
	isUnsaved: PropTypes.bool,
	isInternalEmployee: PropTypes.bool.isRequired,
	listState: PropTypes.object.isRequired,
	languages: studyLanguagesShape.isRequired,
	moduleType: PropTypes.string.isRequired,
	openMediaManager: PropTypes.func.isRequired,
	regenerateCodeValues: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setValues: PropTypes.func.isRequired,
	showNoneOfTheseOption: PropTypes.bool.isRequired,
	toggleOption: PropTypes.func.isRequired,
	upperLists: PropTypes.arrayOf(PropTypes.object.isRequired),
	values: PropTypes.object.isRequired,
}

export default ChoiceOptions
